import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Helmet } from "react-helmet";
import Code from './screens/Code';
import Home from './screens/Home';
import Credits from './screens/Credits';
import Conditions from './screens/Conditions';
import PrivacyPolicy from './screens/PrivacyPolicy';
import Dice from './screens/Dice';
import DiceLink from './screens/DiceLink';
import Go from './screens/Go';
import Gift from './screens/Gift';
import MarketPlaceEvent from './screens/MarketPlaceEvent';
import Unsubscribe from './screens/Unsubscribe';
import EarlyBird from './screens/EarlyBird';
import DownloadEarlyBird from './screens/DownloadEarlyBird';
import CompensationComponent from './screens/CompensationComponent';
import OAuth2Redirect from './screens/OAuth2Redirect';
import Waitlist from './screens/Waitlist';

function App() {
  return (
    <>
      <Helmet>
        <script src="https://js.sentry-cdn.com/3e8eb2e7fb284233b5c3763b05f08e29.min.js" crossorigin="anonymous"></script>
      </Helmet>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/credits" element={<Credits />} />
          <Route path="/conditions" element={<Conditions />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/t/:id" element={<Code />} />
          <Route path="/waitlist/:code" element={<Waitlist />} />
          <Route path="/dice-link" element={<DiceLink />} />
          <Route path="/dice/exchange" element={<DiceLink />} />
          <Route path="/dice" element={<Dice />} />
          <Route path="/go" element={<Go />} />
          <Route path="/marketplaceEvent/:id" element={<MarketPlaceEvent />} />
          <Route path="/gft/:id" element={<Gift />} />
          <Route path="/unsubscribe/:token?" element={<Unsubscribe />} />
          <Route path="/early-bird" element={<EarlyBird />} />
          <Route path="/primavera-pro" element={<DownloadEarlyBird />} />
          <Route path="/madrid-2023-compensation" element={<CompensationComponent />} />
          <Route path="/oauth2/redirect" element={<OAuth2Redirect />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
