import React from "react"
import Container from "../components/Container";
import Head from "../components/Head";
import QRCode from "react-qr-code"
import copy from '../assets/svg/copy';
import DownloadApps from "../components/DownloadApps";
import {
  useLocation,
  useParams
} from "react-router-dom"
import PrivacyButtons from "../components/PrivacyButtons";

const Code = () => {
  const { pathname } = useLocation();
  const { id } = useParams();

  const domain = window.location.hostname;
  const currentDomain = domain === "acst.app" ? "accessticket.app" : "acst.app";

  const TICKETID = id || pathname?.split('/')[pathname?.split('/').length - 1];

  function copyText() {
    if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
      return navigator.clipboard.writeText(TICKETID);
    }
  }

  return (
    <Container>
      {/* DESKTOP */}
      <Head paragraphsClassName="hidden md:block" paragraphs={[
        `Scan this QR code with the AccessTicket app, or manually introduce the following code:`
      ]} />
      <span className="hidden md:block text-white font-bold text-2xl">{TICKETID}</span>
      {/* QR */}
      <div className="hidden mt-12 md:block">
        <div className="p-6 bg-white rounded-5xl">
          <QRCode value={`https://${currentDomain}/t/${TICKETID}`} />
        </div>
      </div>
      {/* MOBILE */}
      <div className="mt-10 w-full flex justify-center">
        <DownloadApps />
      </div>
      <div className="flex flex-col items-center justify-between md:hidden">
        <div className="text-center">
          <p className="text-white text-base font-americaLight">After downloading the app, click below</p>
        </div>
        <a href={`https://${currentDomain}/t/${TICKETID}`} className="w-full py-4 rounded-xl bg-green mt-6 block text-center">
          <span className="text-base text-black font-americaBold">Add your ticket</span>
        </a>
        <div className="text-center">
          <p className="text-white text-base font-americaLight mt-6">or add your ticket with this code in the app:</p>
          {/* <span className="md:block text-white font-bold text-2xl">{TICKETID}</span> */}
          <button onClick={() => copyText()} className='flex gap-3 w-full items-center justify-center h-full mt-6'>
            <p className='text-white text-md font-semibold'>{TICKETID}</p>
            <div className='text-green'>{copy}</div>
          </button>
        </div>
      </div>
       <PrivacyButtons />

    </Container>
  )
}

export default Code
