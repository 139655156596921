import React from "react"
import Container from "../components/Container";
import Head from "../components/Head";
import QRCode from "react-qr-code"
import copy from '../assets/svg/copy';
import DownloadApps from "../components/DownloadApps";
import {
  useLocation,
  useParams
} from "react-router-dom"
import PrivacyButtons from "../components/PrivacyButtons";

const Waitlist = () => {
  const { pathname } = useLocation();
  const { code } = useParams();

  const domain = window.location.hostname;
  const currentDomain = domain === "acst.app" ? "accessticket.app" : "acst.app";

  const WAITLISTCODE = code || pathname?.split('/')[pathname?.split('/').length - 1];

  function copyText() {
    if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
      return navigator.clipboard.writeText(WAITLISTCODE);
    }
  }

  return (
    <Container>
      {/* DESKTOP */}
      <Head paragraphsClassName="hidden md:block" paragraphs={[
        `Scan this QR code to open the AccessTicket app on you device with your account logged in.`
      ]} />
      {/* QR */}
      <div className="hidden mt-12 md:block">
        <div className="p-6 bg-white rounded-5xl">
          <QRCode value={`https://${currentDomain}/waitlist/${WAITLISTCODE}`} />
        </div>
      </div>
      {/* MOBILE */}

      <div className="flex flex-col items-center justify-between md:hidden">
        <div className="text-center">
          <p className="text-white text-base font-americaLight">Go to AccessTicket on you device with your account logged in.</p>
        </div>
        <a href={`https://${currentDomain}/waitlist/${WAITLISTCODE}`} className="w-full py-4 rounded-xl bg-green mt-6 block text-center">
          <span className="text-base text-black font-americaBold">Go to the app</span>
        </a>

      </div>
      <PrivacyButtons />
    </Container>
  )
}

export default Waitlist