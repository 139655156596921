import React from "react"
import Container from "../components/Container";

const Home = () => {
  return (
    <Container>
      <div className="items-left justify-between w-full mt-2 flex flex-col text-white">
        <p dir="ltr"><strong>"ACCESS TICKET" APPLICATION TERMS AND CONDITIONS</strong></p>
        <p dir="ltr">PRIMAVERA SOUND, S.L. ("Primavera Sound") is a Spanish company with registered office at Calle Roc Boronat, 142, 3º, 08018 - Barcelona (Spain), and tax identification number B-61978987, duly registered at the Barcelona Commercial Registry in General Volume&nbsp; 31,815, Page 45, Sheet number B-197,256, and its contact email address is: support@accessticket.app.</p>
        <p dir="ltr">PRIMAVERA SOUND holds all Intellectual and Industrial Property Rights (as defined below) or any other rights over the "Access Ticket" application (the "Application" or the "App").</p>
        <p dir="ltr">The use of the App is subject to the following terms and conditions (the "Terms and Conditions").</p>
        <p dir="ltr"><strong>1. Acceptance of the Application</strong></p>
        <p dir="ltr">By accepting these Terms and Conditions, the User (as defined below) expressly agrees to be bound by this document. In addition, the use of the Application is subject to the privacy policy outlined at <a href="https://www.primaverasound.com/en/privacy-policy-accessticket">https://www.primaverasound.com/en/privacy-policy-accessticket</a>.&nbsp;</p>
        <p dir="ltr">Downloading and using the Application implies acceptance and compliance with these Terms and Conditions.</p>
        <p dir="ltr"><strong>2. Purpose and scope</strong></p>
        <p dir="ltr">The purpose of the Application is to provide users (the "User") with better experience around the Primavera Sound festival as well as at events organized by third parties that have contracted Access Ticket to provide attendee management and access services. In particular, the Application will allow the User to:</p>
        <p dir="ltr"></p>
        <p dir="ltr">(i) Create and manage a User account, which may be used for the other purposes indicated below, as well as to upload and store your festival tickets, allow the management of your bookings for complementary events organized by Primavera Sound and/or third parties, and in general, make the most of their experience.</p>
        <p></p>
        <ol></ol>
        <p><br /></p>
        <p dir="ltr"></p>
        <p dir="ltr">(ii) Verify their identity before accessing the Primavera Sound Festival (the "Festival") or any other event that takes place within the framework of the Festival, so that the User can skip a manual verification and, therefore, queues and possible delays when accessing the Event. In fact, when verifying their identity through the Application, the User’s ticket uploaded in the Application will be automatically validated, and the User may access the Festival venue directly using the QR code generated in the Application as a result of this. For more information on the process followed to verify the identity of the users and allow the electronic validation of their tickets, please consult the <a href="https://www.primaverasound.com/es/tickets-conditions-primavera-sound-madrid-barcelona-2023">Applicable Standard Terms of Sale&nbsp;</a></p>
        <p></p>
        <ol></ol>
        <p><br /></p>
        <p dir="ltr"></p>
        <p dir="ltr">(iii) Access and sign up for the memberships that Primavera Sound may offer and obtain accreditations ( "Plastic Club") to access other events organized by Primavera Sound and benefit from their advantages.</p>
        <p></p>
        <ol></ol>
        <p><br /></p>
        <p dir="ltr"></p>
        <p dir="ltr">(iv) Access events organized by third parties where Access Ticket provides management services and access to attendees.</p>
        <p></p>
        <ol></ol>
        <p><br /></p>
        <p dir="ltr"></p>
        <p dir="ltr">(v) Purchase products and services through the Application, including, among others, merchandising and tickets for other events organized by Primavera Sound; and</p>
        <p></p>
        <ol></ol>
        <p><br /></p>
        <p dir="ltr">(vi) Purchase food or drink during their attendance at the Festival or any other event organized by Primavera Sound.</p>
        <p></p>
        <ol></ol>
        <p dir="ltr">In relation to purchases that the User may make through the Application, in accordance with (iii) and (iv) above, the User will be redirected to the corresponding payment platform (in this case, Redsys).&nbsp;</p>
        <p dir="ltr"><strong>3. Using the Application</strong></p>
        <p dir="ltr">The User agrees not to use the Application for purposes that are unlawful, prohibited or contrary to these Terms and Conditions, harmful to the rights and interests of third parties, or that may damage the reputation of Primavera Sound. The User also agrees not to use the Application in a way that may render it useless, delete it, damage it or prevent its normal use, integrity and stability. The User agrees not to delete, circumvent or disable any safety-related Application function.</p>
        <p dir="ltr">To the maximum extent permitted by applicable law, the User will hold Primavera Sound harmless in view of any claims, complaints, actions and objections from third parties arising from or related to their breach of these Terms and Conditions or their improper use of the Application. As a result, the User will be liable for the costs of any damages Primavera Sound is ordered to pay as a result of litigation, preliminary proceedings, or any other claims arising from that breach or improper use, including all expenses and costs incurred in defending Primavera Sound's interests and any resulting lawyer fees.</p>
        <p dir="ltr">By using the Application, The User represents and warrants that they will not use the Application for purposes other than their personal use.</p>
        <p dir="ltr"><strong>4. INTELLECTUAL AND INDUSTRIAL PROPERTY RIGHTS</strong></p>
        <p dir="ltr">All Intellectual and Industrial Property Rights over the Application and the Festival are owned by Primavera Sound or its licensees. "Intellectual and Industrial Property Rights" means any copyrights (including software rights), rights on databases, rights on designs, patents, trademarks, trade names, practical knowledge, trade secrets and other proprietary knowledge and information, domain names, goodwill and reputation protection rights, and all intellectual property rights and forms of protection of a similar nature to any of the above or that have equivalent effects anywhere in the world, and all rights arising from licenses and consents as regards any of the rights and forms of protection mentioned in this definition.</p>
        <p dir="ltr">Therefore, The User may not use, reproduce, transfer, transform, distribute or exploit the Intellectual and Industrial Property Rights in any way, except as part of the service included in the Application and exclusively for private purposes.</p>
        <p dir="ltr">Except as expressly authorized by Primavera Sound, when the Application is downloaded, the User may not:</p>
        <p dir="ltr"></p>
        <p dir="ltr">(i) Sell, license, distribute, copy, modify, execute or publicly display, transfer, publish, edit, adapt, create derivative works or make unauthorized use of the Application or any of its elements. Primavera Sound and its licensees reserve all the rights not expressly granted under these Terms and Conditions; or</p>
        <p></p>
        <ol></ol>
        <p><br /></p>
        <p dir="ltr"></p>
        <p dir="ltr">(ii) Reverse engineer, decompile, disassemble or otherwise attempt to discover the source code of the Application or any part of it, except and only to the extent that this activity is expressly permitted by applicable law despite this limitation.</p>
        <p></p>
        <ol></ol>
        <p dir="ltr">Primavera Sound is entitled to block any User from accessing certain Application services. If the User infringes these Standard Terms of Business, third-party rights or the applicable law and, in particular, the commitments assumed under this clause, Primavera Sound will retain all other additional rights that may correspond to it against the User and, in particular, in relation to bringing criminal or civil actions.</p>
        <p dir="ltr">The User acknowledges and accepts that the use of the Application does not entail any license or assignment of Intellectual and Industrial Property Rights on the Application, nor does it constitute authorization to create developments arising from the Application.</p>
        <p dir="ltr">Nonetheless, Primavera Sound grants the User a non-exclusive and non-transferable license for the use of the Application, functionalities, content and other benefits available in that Application, which will be subject to the other terms included in these Terms and Conditions, as well as the applicable legislation.</p>
        <p dir="ltr"><strong>5. Links</strong></p>
        <p dir="ltr">The Application may include links to third-party applications or websites that are not owned or controlled by Primavera Sound. Primavera Sound assumes no liability for the content and practices of third-party sites. Access to and use of these linked sites and their content is at the User's risk. These Terms and Conditions do not regulate the User’s use of any site or service other than the Application. The User must review the applicable terms and policies of any linked site.</p>
        <p dir="ltr">Likewise, Primavera Sound cannot control the information, content, products or services provided by third parties that have established links to the Application. Consequently, Primavera Sound declines any liability that may arise from the User using third-party functionalities, technologies, services or platforms. The User agrees to hold Primavera Sound harmless from any liability or payment of damages as a result of any infringement or incident related to their use of those third-party functionalities, technologies, services or platforms, despite being available through the Application.</p>
        <p dir="ltr"><strong>6.&nbsp; Amendments to the Terms and Conditions and scope of the Application services</strong></p>
        <p dir="ltr">Primavera Sound is entitled to amend or update these Terms and Conditions at any time. The User will be expressly informed, within a reasonable period, of any change relating to the Terms and Conditions of the Application. The use of the latest updated version of the Application is advised, where the latest version of these terms and conditions of use will be found.</p>
        <p dir="ltr">To use the Application, the User must expressly accept the terms and conditions in force at any given time and that will be presented on the Application screen on each occasion.</p>
        <p dir="ltr">Primavera Sound also reserves the right to modify, extend or reduce the scope of the services comprising the Application, or to change its functionalities at any time. This is due, in particular, to the rapid evolution of new technologies, which requires Primavera Sound to make regular adjustments to the technical content of the Application.</p>
        <p dir="ltr">Downloading the Application and access to the services offered is free for personal and non-commercial use. However, the services offered through the Application could involve data consumption. Primavera Sound is not liable for the costs of communication, data transmission or Internet connection incurred by the User. Consult your telecommunications operator for more information.</p>
        <p dir="ltr"><strong>7. Availability of the Application</strong></p>
        <p dir="ltr">Primavera Sound will endeavor to ensure the Application is working correctly. However, Primavera Sound cannot ensure that there will be no service interruptions when repairing or maintaining the Application. Primavera Sound will take the appropriate measures to reduce these interruptions.</p>
        <p dir="ltr"><strong>8. Responsibilities</strong></p>
        <p dir="ltr">The User uses the Application at their own risk. By accessing the Application, the User agrees to use it in accordance with applicable legislation and will be liable to Primavera Sound or third parties for any losses that may be caused as a result of breaching that obligation. Primavera Sound is not liable for any damage or alterations that the User’s equipment may suffer as a result of installing or using the Application.</p>
        <p dir="ltr">In any case, the User will be fully responsible for the data and content transferred or communicated to Primavera Sound. Primavera Sound does not control or monitor any of this content, unless a competent court or an administrative decision provides otherwise. Primavera Sound reserves the right to withdraw or prevent the display of that content until the ownership of the material in question or its lawfulness is proved.</p>
        <p dir="ltr">Primavera Sound assumes its share of liability by law, in particular as regards liability for intentional acts or gross negligence in relation to the operation of the Application and for the warranties expressly granted by it.</p>
        <p dir="ltr">Primavera Sound is not liable for any possible security errors that may occur or for any damage that may be caused to the User's device (hardware and software) or to the files or documents stored on it as a result of the presence of a virus on the User's device used to access the Application's services and content, failures in the Internet connection, telephone failures, interference, omissions or disconnections in the operation of the Application due to causes not attributable to Primavera Sound.</p>
        <p dir="ltr">The Application may include advertising content or offers, in which case the advertiser or offering party will be responsible for the included material and compliance with applicable legislation. Primavera Sound will not be liable for any error, inaccuracy or irregularity of that content. In any case, and to file any claim related to the Application's advertising content, the User may send an email to support@accessticket.app.</p>
        <p dir="ltr">Primavera Sound does not generally control the User’s use of the Application. In particular, Primavera Sound does not guarantee that the User's use of the Application is aligned with the law, these Standard Terms of Business, morals, public policy and generally accepted good customs. The User is solely responsible for the Use they make of the Application. As a result, Primavera Sound will not be liable for the User’s use of the Application content that may constitute a breach of domestic or international law, or intellectual or industrial property rights or any other third-party right.</p>
        <p dir="ltr"><strong>&nbsp;9. Data protection</strong></p>
        <p dir="ltr">Primavera Sound will protect the User’s personal data and will only use them to the extent allowed by law or the User’s consent. The User can find more information on this in the Application’s privacy policy outlined at <a href="https://www.primaverasound.com/en/privacy-policy-accessticket">https://www.primaverasound.com/en/privacy-policy-accessticket</a></p>
        <p dir="ltr"><strong>10. Governing law, jurisdiction and alternative dispute resolution (ADR)</strong></p>
        <p dir="ltr">These Standard Terms of Business will be governed by Spanish law. If the User is a consumer, these Terms and Conditions will also be subject to any applicable local legislation.</p>
        <p dir="ltr">All conflicts arising from or related to these Terms and Conditions will be subject to the non-exclusive jurisdiction of the Spanish courts of the city of Barcelona (Spain), or to the court in the town where the User resides if they are acting as consumers.</p>
        <p dir="ltr">The User may also submit any dispute arising from or related to these Terms and Conditions to an alternative dispute resolution ("ADR") procedure if they are acting as consumers. The list of ADR platforms available in the European Commission is available at the following link: <a href="https://ec.europa.eu/consumers">https://ec.europa.eu/consumers</a></p>
        <p dir="ltr"><em>Last updated: October 2023</em></p>
        <p><br /></p>
      </div>

      <div className="items-center justify-between w-full mt-2 flex flex-col text-white">
        <div className="w-3/3 mt-24">
          <div className="text-xs pb-2">Proyecto financiado por el IANEM, Ministerio de Cultura y Deporte</div>
          <div className="text-xs pb-2">Financiado por la Unión Europea-Next Generation EU</div>
          <div className="flex items-center flex-row justify-between w-full text-white">
            <img width="180px" className="pr-2" src="https://assets-img.primaverasound.com/0x300/psweb/nvude2d1q1y7v7a8tv3v_1697642759168.png" alt="Logotipos" />
            <img width="250px" className="pr-4" src="https://assets-img.primaverasound.com/0x300/psweb/wstdbiir426t2nr434cj_1697643253475.png" alt="Logotipos" />
            <img width="130px" className="pr-2" src="https://assets-img.primaverasound.com/0x300/psweb/9gamesxx489wc1umrhpd_1697642656436.png" alt="Logotipos" />
          </div>
        </div>
      </div>
    </Container>
  )
}

export default Home