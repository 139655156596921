import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Container from "../components/Container";
import Head from "../components/Head";
import PrivacyButtons from "../components/PrivacyButtons";

const OAuth2Redirect = () => {
  const [status, setStatus] = useState("Procesando autenticación...");
  const [error, setError] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Obtener los parámetros de la URL
    const params = new URLSearchParams(location.search);
    const code = params.get("code");
    // eslint-disable-next-line no-unused-vars
    const state = params.get("state");
    const error = params.get("error");

    if (error) {
      setStatus("Error de autenticación");
      setError(error);
      return;
    }

    if (!code) {
      setStatus("Error de autenticación");
      setError("No se recibió el código de autorización");
      return;
    }

    // Aquí puedes implementar la lógica para intercambiar el código por un token
    // Por ahora, solo mostraremos un mensaje de éxito
    setStatus("Autenticación exitosa");

    // Ejemplo de redirección después de procesar la autenticación
    // Puedes modificar esto según tus necesidades
    const timer = setTimeout(() => {
      navigate("/");
    }, 3000);

    return () => clearTimeout(timer);
  }, [location, navigate]);

  return (
    <Container>
      <Head
        paragraphs={[
          status,
          error ? `Error: ${error}` : "Serás redirigido automáticamente...",
        ]}
      />
      <div className="flex flex-col items-center justify-center w-full mt-12">
        {!error ? (
          <div className="text-green text-2xl font-americaBold">
            ✓ Autenticación completada
          </div>
        ) : (
          <div className="text-red-500 text-2xl font-americaBold">
            ✗ Error en la autenticación
          </div>
        )}
      </div>

      {/* Añadimos los enlaces a TOC y Política de Privacidad */}
      <PrivacyButtons />

      {/* Añadimos los logotipos y la información de financiación */}
      <div className="items-center justify-between w-full mt-2 flex flex-col text-white">
        <div className="w-3/3 mt-24">
          <div className="text-xs pb-2">Proyecto financiado por el IANEM, Ministerio de Cultura y Deporte</div>
          <div className="text-xs pb-2">Financiado por la Unión Europea-Next Generation EU</div>
          <div className="flex items-center flex-row justify-between w-full text-white">
            <img width="180px" className="pr-2" src="https://assets-img.primaverasound.com/0x300/psweb/nvude2d1q1y7v7a8tv3v_1697642759168.png" alt="Logotipos" />
            <img width="250px" className="pr-4" src="https://assets-img.primaverasound.com/0x300/psweb/wstdbiir426t2nr434cj_1697643253475.png" alt="Logotipos" />
            <img width="130px" className="pr-2" src="https://assets-img.primaverasound.com/0x300/psweb/9gamesxx489wc1umrhpd_1697642656436.png" alt="Logotipos" />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default OAuth2Redirect;