const Container = ({ children, className, backgroundImage = true }) => {
  return (
    <div
    style={backgroundImage ? {
      backgroundImage: "url(/assets/background.png)",
      backgroundRepeat: 'no-repeat',
      backgroundAttachment: 'fixed',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    } : {}}
    className={`flex flex-col items-center w-full h-full min-h-screen px-6 py-12 md:py-32 ${!backgroundImage ? "bg-darkGray" : ""} ${className || ""}`}
    >
      {children}
    </div>
  )
}

export default Container
