import React from "react"
import Container from "../components/Container";
import Head from "../components/Head";

const Home = () => {
  return (
    <Container>
      <Head paragraphs={[
        'Créditos de la aplicación',
        'AccessTicket parte de una idea del equipo Primavera Labs, división de I+D+I de \nPrimavera Sound S.L.',
        'Programación: Primavera Labs + Dallonses',
        'Diseño UX+UI: Vasava',
      ]} />


      <div className="items-center justify-between w-full mt-2 flex flex-col text-white">
        <div className="w-3/3 mt-24">
          <div className="text-xs pb-2">Proyecto financiado por el IANEM, Ministerio de Cultura y Deporte</div>
          <div className="text-xs pb-2">Financiado por la Unión Europea-Next Generation EU</div>
          <div className="flex items-center flex-row justify-between w-full text-white">
            <img width="180px" className="pr-2" src="https://assets-img.primaverasound.com/0x300/psweb/nvude2d1q1y7v7a8tv3v_1697642759168.png" alt="Logotipos" />
            <img width="250px" className="pr-4" src="https://assets-img.primaverasound.com/0x300/psweb/wstdbiir426t2nr434cj_1697643253475.png" alt="Logotipos" />
            <img width="130px" className="pr-2" src="https://assets-img.primaverasound.com/0x300/psweb/9gamesxx489wc1umrhpd_1697642656436.png" alt="Logotipos" />
          </div>
        </div>
      </div>
    </Container>
  )
}

export default Home