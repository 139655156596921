import React, { useState, useEffect } from 'react';
import Container from '../components/Container';
import Head from '../components/Head';
import { useParams } from 'react-router-dom';
import PrivacyButtons from '../components/PrivacyButtons';

const Unsubscribe = () => {
  const [email, setEmail] = useState('');
  const [isRequested, setIsRequested] = useState(false);
  const [isUnsubscribe, setIsUnsubscribe] = useState(false);
  const [isToken, setIsToken] = useState(false);
  const [isSent, setIsSent] = useState(false);
  // const [token, setToken] = useState('');

  const URL = 'https://api.acst.app/graphql';

  let { token } = useParams();

  useEffect(() => {
    if (token) {
      setIsRequested(true);
      setIsUnsubscribe(false);
      setIsToken(true);
      setIsSent(false);
    }
  }, [token]);

  const onSubmit = async (e) => {
    e.preventDefault();

    const query = JSON.stringify({
      query: `mutation UnsubscribeEmail($email: String!) {
        unsubscribeEmail(email: $email) {
          sent
        }
      }
      `,
      variables: { email },
    });

    const res = await fetch(URL, {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      body: query,
    });

    const {
      data: { unsubscribeEmail },
    } = await res.json();

    if (unsubscribeEmail.sent === true) {
      setIsRequested(true);
      setIsSent(true);
    }
  };

  const unsubscribe = async () => {
    const query = JSON.stringify({
      query: `mutation Unsubscribe($token: String!) {
        unsubscribe(token: $token) {
          unsubscribed
        }
      }
      `,
      variables: { token },
    });

    const res = await fetch(URL, {
      headers: { 'content-type': 'application/json' },
      method: 'POST',
      body: query,
    });

    const { data } = await res.json();

    if (data.unsubscribe && data.unsubscribe.unsubscribed === true) {
      setIsToken(false);
      setIsUnsubscribe(true);
    }
  };

  return (
    <Container>
      {/* DESKTOP */}
      {!isRequested && (
        <>
          <Head
            paragraphsClassName=" md:block"
            paragraphs={[
              `Please, enter your email to unsubscribe from AccessTicket:`,
            ]}
          />

          <form className="text-center" onSubmit={onSubmit}>
            <input
              type="email"
              className="w-72 px-3 py-4 rounded-md font-americaLight md:w-80"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />

            <button
              className="md:ml-12 w-40 py-4 mt-6 text-center rounded-xl bg-green"
              type="submit"
            >
              Unsubscribe
            </button>
          </form>
        </>
      )}
      {isSent && (
        <>
          <Head
            paragraphsClassName=" md:block"
            paragraphs={[
              `We have sent you an email to confirm the unsubscription.`,
            ]}
          />
        </>
      )}

      {isToken && (
        <>
          <Head
            paragraphsClassName=" md:block"
            paragraphs={[`Please, click here to confirm your unsubscription.`]}
          />
          <button
            onClick={unsubscribe}
            className="w-40 py-4 mt-4 text-center rounded-xl bg-green"
          >
            Confirm
          </button>
        </>
      )}
      {isUnsubscribe && (
        <>
          <Head
            paragraphsClassName=" mt-24 md:block"
            paragraphs={[`You have been unsubscribed.`]}
          />
        </>
      )}

      {/* MOBILE */}

      <div className="flex items-center justify-between w-full mt-24 md:w-1/4">
        <a
          href="https://apps.apple.com/es/app/accessticket/id1534897902"
          className="flex-1 mr-2 md:mx-3"
        >
          <img src="/assets/appstore.png" alt="AppStore" />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=app.testwallet.accessticket"
          className="flex-1 ml-2 md:mx-3"
        >
          <img src="/assets/playstore.png" alt="GooglePlay" />
        </a>
        <a href="https://appgallery.huawei.com/app/C105891447" className="flex-1 ml-2 md:mx-3">
          <img src="/assets/huawei.png" alt="Huawei" />
        </a>
      </div>
      <PrivacyButtons />
    </Container>
  );
};

export default Unsubscribe;
